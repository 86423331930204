import React from 'react';
import Form from 'react-bootstrap/Form';
import legende from './helper/legende';
import {formatDate} from '../../component/const/formatDate';
import {ValidateMessage} from '../../component/const/ValidateMessage';
import IntlMessage, {IntlMessageValue} from '../../component/const/IntlMessage';
function input(data: any, i: number,optionalFuncFirst:any = false,Store:any) {

  let required = false;
  if(data.required !== undefined && data.required !== null && data.required !== false){
    required = true;
  }

  let Setautocomplete;
  if(data.type === 'password') {
    Setautocomplete = "new-password";
  } else {
    Setautocomplete = "on";
  }
  const handlefunc  = (e:any) => {
    optionalFuncFirst(e);
  }


  const JSONAppend = () => {

      let JsonP = JSON.parse((document.getElementById(data.jsonID) as HTMLInputElement).value);

      let dataValue = (document.getElementById(data.id) as HTMLInputElement).value;

      console.log(JsonP);

      JsonP.item[data.appendJson][data.appendJsonField] = dataValue;

      (document.getElementById(data.jsonID) as HTMLInputElement).value = JSON.stringify(JsonP);

      console.log(JsonP);
  }


 const clickHandler = () => {
    if(data.id === undefined) {
      data.id = 'password'+i;
    }
   let password =(document.getElementById(data.id) as HTMLInputElement);

   if(password.type === 'password') {
     password.type = 'text';
   } else {
     password.type = 'password'
   }

 }
    const appendValue = () => {
        let AppendValue = (document.getElementById(data.appendid) as HTMLInputElement);
        let Value = (document.getElementById(data.id) as HTMLInputElement);


        AppendValue.value = Value.value;
    }
  let show = (<></>)
  if(data.type === 'password') {
    show = (<>
        <span onClick={clickHandler} className="material-icons">
        visibility
        </span>
    </>)
  }

  if(data.jsonID !== undefined) {
      return (
          <>
              <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
                  <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
                  <Form.Control autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} name={data.name} id={data.id} type={data.type} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                                onChange={()=>JSONAppend()}/>
                  <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
              </Form.Group>
              {show}
              {legende(data)}
          </>
      );
  } else if(data.max !== undefined) {



    let maxContent = data.max
    let InitialChars = maxContent - (data.value === null || data.value === undefined ? 0 : data.value.length) ;
    const setFormattedContent = (value:any,max:any) => {

      let chars = max - value.length ;

      // @ts-ignore
        document.getElementById('content'+i+data.label).innerHTML = IntlMessageValue('de',"common.remaining_chars",Store,"replace","[n]",chars) ;
      if(data.seo !== undefined) {
        handlefunc(value)
      }
        if(data.appendid !== undefined ) {
            appendValue()
        }
    }

    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label}>
          <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} type={data.type} id={data.id} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required} onChange={event => setFormattedContent(event.target.value,maxContent)}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
          <p id={'content'+i+data.label}>
              <IntlMessage Store={Store} messageId="common.remaining_chars" preperator="replace" param="[n]" replace={InitialChars} />
          </p>
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );


  }else if(data.type === 'number') {
      return (
          <>
              <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
                  <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
                  <Form.Control name={data.name} autoComplete={Setautocomplete} min={data.min} pattern={data.pattern} title={data.title}  type={data.type} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                                onChange={()=>appendValue()}/>
                  <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
              </Form.Group>
              {show}
              {legende(data)}
          </>
      );
  }  else if(data.readonly !== null && data.readonly !== undefined && data.readonly !== false){
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
          <Form.Control disabled={true} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} name={data.name} id={data.id} type={data.type} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
          onChange={()=>appendValue()}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );

  } else if(data.type === 'password' && data.id !== undefined) {
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.id} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.id}>{data.label}</Form.Label>
          <div>
            <Form.Control style={{width: '95%',float: 'left'}} name={data.name} pattern={data.pattern} title={data.title} autoComplete={Setautocomplete} type={data.type} id={data.id} placeholder={data.placeholder} defaultValue={formatDate(data.value)} key={"formFileInput"+i+data.label} required={required}
                          onChange={()=>appendValue()}/>
            <p style={{color:'white',width: '5%',float: 'right',textAlign: 'center',background: 'lightgray',paddingTop:'7px',height: '37.53px'}}>{show}</p>
          </div>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>

        {legende(data)}
      </>
    );
  } else if(data.type === 'password' && data.id === undefined) {
    data.id = 'password'+i;
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.id} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.id}>{data.label}</Form.Label>
          <div>
            <Form.Control style={{width: '95%',float: 'left'}} name={data.name}  pattern={data.pattern} title={data.title}autoComplete={Setautocomplete} type={data.type} id={data.id} placeholder={data.placeholder} defaultValue={formatDate(data.value)} key={"formFileInput"+i+data.label} required={required}
                          onChange={()=>appendValue()}/>
            <p style={{color:'white',width: '5%',float: 'right',textAlign: 'center',background: 'lightgray',paddingTop:'7px',height: '37.53px'}}>{show}</p>
          </div>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>

        {legende(data)}
      </>
    );
  }else if(data.type === 'onclickHidden') {
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} id={data.containerID} key={"formFile"+i+data.label} className="mb-3" style={{display:'none'}}>
          <Form.Label key={"formFileLabel"+i+data.label} >{data.label}</Form.Label>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title}  type={'text'} placeholder={data.placeholder} key={"formFileInput"+i+data.label} defaultValue={data.value} required={required}
                        onChange={()=>appendValue()}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    )
  }else if(data.type === 'onclickText') {
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} id={data.containerID} key={"formFile"+i+data.label} className="mb-3">
          <Form.Label key={"formFileLabel"+i+data.label} >{data.label}</Form.Label>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title}  type={'text'} placeholder={data.placeholder} key={"formFileInput"+i+data.label} defaultValue={data.value} required={required}
                        onChange={()=>appendValue()}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    )
  }else if(data.type === 'hidden' && data.id === undefined) {
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label}>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} type={data.type} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                        onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );
  }  else if(data.type === 'hidden' && data.id !== undefined) {
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label}>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} type={data.type} id={data.id} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                        onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );

  }else if(data.type === 'date') {


    if(data.format !== undefined) {
      return (
        <>
          <Form.Group controlId={"formFile" + i + data.label} key={"formFile" + i + data.label} className="mb-3">
            <Form.Label key={"formFileLabel" + i + data.label}>{data.label}</Form.Label>
            <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght}
                          pattern={data.pattern} title={data.title} type={data.type} placeholder={data.placeholder}
                          defaultValue={data.value} key={"formFileInput" + i + data.label}
                          required={required} onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
          </Form.Group>
          {show}
          {legende(data)}
        </>
      );
    } else {
      return (
        <>
          <Form.Group controlId={"formFile" + i + data.label} key={"formFile" + i + data.label} className="mb-3">
            <Form.Label key={"formFileLabel" + i + data.label}>{data.label}</Form.Label>
            <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght}
                          pattern={data.pattern} title={data.title} type={data.type} placeholder={data.placeholder}
                          defaultValue={formatDate(data.value)} key={"formFileInput" + i + data.label}
                          required={required} onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
          </Form.Group>
          {show}
          {legende(data)}
        </>
      );
    }
  } else if(data.value !== null && data.id === undefined) {


    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} type={data.type} id={data.id} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                        onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );
  } else {
  if(data.value !== null && data.id !== undefined) {
    let visibled = '';
    if(data.visible === null ){
      visibled = 'elementsIMghidden';
    }
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
          <Form.Control className={visibled} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} name={data.name} id={data.id} type={data.type} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                        onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );
    } else if(data.readonly !== null && data.readonly !== undefined){
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
          <Form.Control disabled={true} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title} name={data.name} id={data.id} type={data.type} placeholder={data.placeholder} defaultValue={data.value} key={"formFileInput"+i+data.label} required={required}
                        onChange={()=>appendValue()}/>
          <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );

  } else {
    return (
      <>
        <Form.Group controlId={"formFile"+i+data.label} key={"formFile"+i+data.label} className="mb-3" >
          <Form.Label key={"formFileLabel"+i+data.label}>{data.label}</Form.Label>
          <Form.Control name={data.name} autoComplete={Setautocomplete} maxLength={data.maxLenght} pattern={data.pattern} title={data.title}  type={data.type} placeholder={data.placeholder} key={"formFileInput"+i+data.label} required={required}
                        onChange={()=>appendValue()}/>
            <ValidateMessage valid={(data.valid !== undefined ? data.valid : undefined)} Store={Store} />
        </Form.Group>
        {show}
        {legende(data)}
      </>
    );
  }
  }
} 

export default input;
