import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Form from '../../../../../core/form-serilizer'
import configuration from '../../configuration/Page/faq/posts'
import IntlMessage from '../../../../../core/component/const/IntlMessage'
import { Accordion, Col, Row } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'

function post(props: {
    response: any
    setIndex: any
    elements: any
    Store: any
    setElements: any
    Index: any
}) {
    let Store = props.Store

    if (props.response !== undefined) {
        let NewIndex = props.response.posts.length

        console.log(props.Index)

        let elementHolder = []
        let data = props.response.posts
        for (let x = 0; x < data.length; x++) {
            let Status
            if (data[x].status === 1) {
                Status = 'elementActive'
            }
            if (data[x].status === 0) {
                Status = 'elementDeactive'
            }
            elementHolder.push(
                <>
                    <Accordion.Item
                        eventKey={'' + x + ''}
                        id={'posts' + x}
                        style={{ marginBottom: '10px' }}
                    >
                        <Row>
                            <Col>
                                <Accordion.Button
                                    className={Status}
                                    style={{ border: '1px solid black' }}
                                >
                                    <h3>{data[x].title}</h3>
                                </Accordion.Button>
                            </Col>
                        </Row>
                        <Accordion.Body>
                            {Form(
                                configuration(
                                    'dataColOne',
                                    data[x],
                                    false,
                                    Store,
                                    x
                                )
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                </>
            )
        }

        return (
            <>
                <Button
                    variant={'success'}
                    className={'float-end'}
                    style={{ marginTop: '-35px' }}
                    onClick={() =>
                        newPost(
                            props.Index,
                            props.setIndex,
                            Store,
                            props.setElements,
                            NewIndex,
                            props.elements
                        )
                    }
                >
                    <span className="material-icons">add</span>
                </Button>
                <br style={{ clear: 'both' }} />
                <Accordion id={'posts'}>
                    {elementHolder}
                    {props.elements}
                </Accordion>
            </>
        )
    } else {
        return (
            <>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        <IntlMessage Store={Store} messageId="common.loading" />
                    </span>
                </Spinner>
            </>
        )
    }
}

export default post

const newPost = (
    Index: any,
    setIndex: any,
    Store: any,
    setElements: any,
    oldIndex: any,
    elements: any
) => {
    let NewIndex
    let nowIndex = Index + oldIndex
    let Element = (
        <>
            <Accordion.Item
                eventKey={'' + nowIndex + '_posts'}
                id={'posts' + nowIndex}
                style={{ marginBottom: '10px' }}
            >
                <Row>
                    <Col>
                        <Accordion.Button
                            className={'elementActive'}
                            style={{ border: '1px solid black' }}
                        >
                            <h3>Neuer Post</h3>
                        </Accordion.Button>
                    </Col>
                </Row>
                <Accordion.Body>
                    {Form(
                        configuration('dataColOne', {}, true, Store, nowIndex)
                    )}
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
    NewIndex = Index + 1
    setIndex(NewIndex)
    let allElements = []
    for (let x = 0; x < elements.length; x++) {
        allElements.push(elements[x])
    }
    allElements.push(Element)
    setElements(allElements)
}
