import { useEffect, useState } from 'react'
import { error } from '../../../core/component/const/error.services'
import { faq } from '../request/faq'
import { faqApiType } from '../request/faqApi.interface'

export const ResponseAll = (limit: any, searchValue: any) => {
    const [posts, setPosts] = useState<faqApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await faq
                .getPostsALL(limit, searchValue)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [limit, searchValue])

    return isError ? posts : posts
}

export const Response = (searchValue: any = false) => {
    const [posts, setPosts] = useState<faqApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await (searchValue === false || searchValue === ''
                ? faq.getPosts()
                : faq.getPostsSearch(searchValue)
            )
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [searchValue])

    return isError ? posts : posts
}

export const ResponseFAQDetail = (id: any) => {
    const [posts, setPosts] = useState<faqApiType[]>([])
    const [isError, setIsError] = useState<boolean>(false)
    useEffect(() => {
        ;(async () => {
            await faq
                .getAPost(id)
                .then((data) => {
                    setPosts(data)
                })
                .catch((err) => {
                    error(err)
                    setIsError(true)
                })
        })()
    }, [id])

    return isError ? posts : posts
}
