import React from 'react';
import row from './row';
import TableRow from '@material-ui/core/TableRow';

function TableBodyRow(props: {data: any, tableConfiguration: any,Menu: any, index:number, tableBodyType:any, indexID:any,Store:any,tableBodyPosition:any}) {
  if (props.data.hasOwnProperty('Pages') === false) {
    return (
      <>
        <TableRow key={props.data[props.indexID] + '_' + props.index}  id={props.data[props.indexID] + '_' + props.index}>
          {row(props)}

        </TableRow>

      </>
    )
  } else {
    return(
      <></>
    )
  }
}





export default TableBodyRow;
