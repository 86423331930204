import {ResponseContactsDetail} from "../../api/response/contacts";
import {useNavigate, useParams} from "react-router-dom";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {useSelector} from "react-redux";
import {Form,Tabs,Tab} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {apiRoot} from "../../../../../@WUM/core/RestFullApi/ApiHook";
import {notify_save} from "../../../../../@WUM/core/component/const/notification.service";

const ContactsDetail = (props:{value:any}) => {
    let {id} = useParams<{id: string}>();
    let response = ResponseContactsDetail(id);
    const Store = useSelector((state: any) => state)
    const history = useNavigate();

    const save = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)+"");

        var raw = JSON.stringify({
            "id":((document.getElementById('id') as HTMLInputElement).value !== '' ? (document.getElementById('id') as HTMLInputElement).value : null),
            "type": ((document.getElementById('type') as HTMLInputElement).value !== '' ? (document.getElementById('type') as HTMLInputElement).value : ' '),
            "title": ((document.getElementById('title') as HTMLInputElement).value !== '' ? (document.getElementById('title') as HTMLInputElement).value : ' '),
            "firstname": ((document.getElementById('firstname') as HTMLInputElement).value !== '' ? (document.getElementById('firstname') as HTMLInputElement).value : ' '),
            "lastname": ((document.getElementById('lastname') as HTMLInputElement).value !== '' ? (document.getElementById('lastname') as HTMLInputElement).value : ' '),
            "company": ((document.getElementById('company') as HTMLInputElement).value !== '' ? (document.getElementById('company') as HTMLInputElement).value : ' '),
            "street": ((document.getElementById('street') as HTMLInputElement).value !== '' ? (document.getElementById('street') as HTMLInputElement).value : ' '),
            "zip": ((document.getElementById('zip') as HTMLInputElement).value !== '' ? (document.getElementById('zip') as HTMLInputElement).value : ' '),
            "city": ((document.getElementById('city') as HTMLInputElement).value !== '' ? (document.getElementById('city') as HTMLInputElement).value : ' '),
            "phone":((document.getElementById('phone') as HTMLInputElement).value !== '' ? (document.getElementById('phone') as HTMLInputElement).value : ' '),
            "mobile": ((document.getElementById('mobile') as HTMLInputElement).value !== '' ? (document.getElementById('mobile') as HTMLInputElement).value : ' '),
            "fax": ((document.getElementById('fax') as HTMLInputElement).value !== '' ? (document.getElementById('fax') as HTMLInputElement).value : ' '),
            "email": ((document.getElementById('email') as HTMLInputElement).value !== '' ? (document.getElementById('email') as HTMLInputElement).value : ' '),
            "website": ((document.getElementById('website') as HTMLInputElement).value !== '' ? (document.getElementById('website') as HTMLInputElement).value : ' '),
            "notes": ((document.getElementById('notes') as HTMLInputElement).value !== '' ? (document.getElementById('notes') as HTMLInputElement).value : 0),
            "status": ((document.getElementById('status') as HTMLInputElement).value !== '' ? (document.getElementById('status') as HTMLInputElement).value : 0),
        })



        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders
        };
        let button = (document.getElementById("btn_save") as HTMLButtonElement);

        button.setAttribute("disabled", "disabled");
        fetch(apiRoot+"v2/contact/0/", requestOptions)
            .then(response => response.text())
            .then(result => rewrite())
            .catch(error => errorEvent(error));


    }

    const rewrite = () => {

        notify_save(Store);
        let button = (document.getElementById("btn_save") as HTMLButtonElement);

        button.removeAttribute("disabled");
    }

    const errorEvent = (error:any) => {
        console.log('error', error)
        let button = (document.getElementById("btn_save") as HTMLButtonElement);

        button.removeAttribute("disabled");
    }

    if(response[0] !== undefined ) {

    return (
        <>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                <Tab eventKey="home" title={IntlMessageValue('de','common.contact_information',Store)} key={'allgemein'} id={'allgemein'}
                     style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                    <Form>
                        <input type={'hidden'} id={'id'} value={response[0].id} />
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="company">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.company'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].company}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="street">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.street'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].street}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="zip">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.zip'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].zip}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="city">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.city'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].city}/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="title">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.title'}/>:</Form.Label>
                                <Form.Select aria-label="Floating label select example"
                                             defaultValue={response[0].title}>
                                    <option></option>
                                    <option
                                        value={'Herr'}>{IntlMessageValue('de', 'common.mister', Store)}</option>
                                    <option
                                        value={'Frau'}>{IntlMessageValue('de', 'common.missis', Store)}</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="firstname">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.firstname'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].firstname}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="lastname">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.lastname'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].lastname}/>
                            </Form.Group>


                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="email">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.email'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].email}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="mobile">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.mobil'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].mobile}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="phone">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.phone'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].phone}/>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="website">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.website'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].website}/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} className="mb-3" controlId="fax">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.fax'}/>:</Form.Label>
                                <Form.Control type="text" defaultValue={response[0].fax}/>
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="type">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.type'}/>:</Form.Label>

                                <Form.Select aria-label="Default select example" defaultValue={response[0].type}>
                                    <option></option>
                                    <option value={'company'}>Krankenkasse</option>
                                    <option value={'person'}>Person</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3" controlId="notes">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.remark'}/>:</Form.Label>
                                <Form.Control type="text"  defaultValue={response[0].notes}/>
                            </Form.Group>
                            <Form.Group as={Col} controlId="status">
                                <Form.Label><IntlMessage Store={Store} messageId={'common.status'}/></Form.Label>
                                <Form.Select aria-label="Default select example" defaultValue={response[0].status}>
                                    <option></option>
                                    <option value={1}>Aktiviert</option>
                                    <option value={0}>Deaktiviert</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}>
                            <IntlMessage Store={Store} messageId={'common.back'}/>
                        </Button>

                        <Button variant='success'
                                id={'btn_save'}
                                style={{float: 'right', marginRight: '15px'}}
                                type={'button'}
                                onClick={()=>save()}
                        >
                            <IntlMessage Store={Store} messageId={'common.save'}/>
                        </Button>
                        <br style={{clear: 'both'}} />
                    </Form>
                </Tab>
            </Tabs>
        </>
    )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default ContactsDetail;
