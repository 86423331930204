import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {Form, Tabs, Tab} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import {ResponseAllCustomer} from "../../../../hour/timetracking/api/response/timetracking.tsx";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import newSave from "../../../../../@WUM/core/component/const/newSave";

const ContactsNew = (props: { value: any }) => {


    const Store = useSelector((state: any) => state)
    const history = useNavigate();
    const destination = 'budget';
    const ALLCustomer = ResponseAllCustomer();
    const setup = {
        fields: [
            {
                id: 'id',
                default: null,
                html: 'none'
            },
            {
                id: 'customer_ID',
                default: '',
                html: 'select'
            },
            {
                id: 'budgetname',
                default: '',
                html: 'input'
            },
            {
                id: 'description',
                default: '',
                html: 'input'
            },
            {
                id: 'amount',
                default: '',
                html: 'input'
            },
            {
                id: 'customWageRate',
                default: '',
                html: 'input'
            },
            {
                id: 'start_date',
                default: '',
                html: 'input'
            },
            {
                id: 'end_date',
                default: '',
                html: 'input'
            },
            {
                id: 'turnus',
                default: '',
                html: 'select'
            },
            {
                id: 'creditFromThePreviousYear',
                default: '',
                html: 'input'
            },
            {
                id: 'status',
                default: '',
                html: 'select'
            },
        ],
        btn_save: "btn_save",
        url: "v2/budget/0/",
        destination: destination,
        method: "POST",
        timeout: true
    }



    if (ALLCustomer !== undefined) {
        return (
            <>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                    <Tab eventKey="home" title={IntlMessageValue('de', 'common.contact_information', Store)}
                         key={'allgemein'} id={'allgemein'}
                         style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="mb-3" controlId="budgetname">
                                    <Form.Label><IntlMessage Store={Store}
                                                             messageId={'common.budgetname'}/>:</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="description">
                                    <Form.Label><IntlMessage Store={Store}
                                                             messageId={'common.description'}/>:</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>
                                <FetchSelect Data={ALLCustomer} id={'id'}
                                             controlId={'customer_ID'} value={'lastname'} value2={'firstname'} Uid={0}
                                             label={IntlMessageValue('de', 'common.table.head.employe', Store)}/>

                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} className="mb-3" controlId="start_date">
                                    <Form.Label><IntlMessage Store={Store}
                                                             messageId={'common.start_date'}/>:</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="end_date">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.end_date'}/>:</Form.Label>
                                    <Form.Control type="date"/>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="turnus">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.turnus'}/>:</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                                 placeholder={IntlMessageValue('de', 'common.turnus.placeholder', Store)}>
                                        <option></option>
                                        <option value="0">täglich</option>
                                        <option value="1">monatlich</option>
                                        <option value="2">jährlich</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">

                                <Form.Group as={Col} className="mb-3" controlId="amount">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.amount'}/>:</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="creditFromThePreviousYear">
                                    <Form.Label><IntlMessage Store={Store}
                                                             messageId={'common.creditFromThePreviousYear'}/>:</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="customWageRate">
                                    <Form.Label><IntlMessage Store={Store}
                                                             messageId={'common.customWageRate'}/>:</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>
                                <Form.Group as={Col} className="mb-3" controlId="status">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.status'}/>:</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                                 placeholder={IntlMessageValue('de', 'common.status.placeholder', Store)}>
                                        <option value="1">Aktiviert</option>
                                        <option value="0">Deaktiviert</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Button variant='light' style={{float: 'left', marginLeft: '15px'}}
                                    onClick={() => history(-1)}>
                                <IntlMessage Store={Store} messageId={'common.back'}/>
                            </Button>

                            <Button variant='success'
                                    id={'btn_save'}
                                    style={{float: 'right', marginRight: '15px'}}
                                    type={'button'}
                                    onClick={() => newSave(setup,Store)}
                            >
                                <IntlMessage Store={Store} messageId={'common.save'}/>
                            </Button>
                            <br style={{clear: 'both'}}/>
                        </Form>
                    </Tab>
                </Tabs>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default ContactsNew;

