import {Store} from 'react-notifications-component';
import {IntlMessageValue} from './IntlMessage';


/**
 * Speichert Notification für Änderung der Anbieter Provision
 * @function notifyProvision
 * @example
 * export const notifyProvision = () => store.addNotification({
 * title: IntlMessageValue("notification.service.provision.title"),
 * message: IntlMessageValue("notification.service.provision.message"),
 * type: "success",
 * insert: "top",
 * container: "top-center",
 * animationIn: ["animate__animated", "animate__fadeIn"],
 * animationOut: ["animate__animated", "animate__fadeOut"],
 * dismiss: {
 *   duration: 3000,
 *   onScreen: true
 * }
 * });
 */
export const notifyProvision = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.provision.title",Stores),
  message: IntlMessageValue('de',"notification.service.provision.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }
});


/**
 * Speichert Notification für das ablegen in der Zwischenablage
 * @function notify
 * @example
 export const notify = () => store.addNotification({
  title: IntlMessageValue("notification.service.notify.title"),
  message: IntlMessageValue("notification.service.notify.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.notify.title",Stores),
  message: IntlMessageValue('de',"notification.service.notify.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für Änderung der Anbieter Sichtbarkeit
 * @function notifyvisible
 * @example
 export const notifyvisible = () => store.addNotification({
  title: IntlMessageValue("notification.service.visible.title"),
  message: IntlMessageValue("notification.service.visible.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notifyvisible = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.visible.title",Stores),
  message: IntlMessageValue('de',"notification.service.visible.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});


/**
 * Speichert Notification für Änderung der Anbieter Urlaubsmodus
 * @function notifyvacation
 * @example
 export const notifyvacation = () => store.addNotification({
  title: IntlMessageValue("notification.service.vacation.title"),
  message: IntlMessageValue("notification.service.vacation.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notifyvacation = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.vacation.title",Stores),
  message: IntlMessageValue('de',"notification.service.vacation.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für Änderung der Anbieter Status
 * @function notifyenabled
 * @example
 export const notifyenabled = () => store.addNotification({
  title: IntlMessageValue("notification.service.enabled.title"),
  message: IntlMessageValue("notification.service.enabled.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notifyenabled = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.enabled.title",Stores),
  message: IntlMessageValue('de',"notification.service.enabled.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für Änderung der Anbieter Approve
 * @function notifyapproved
 * @example
 export const notifyapproved = () => store.addNotification({
  title: IntlMessageValue("notification.service.approved.title"),
  message: IntlMessageValue("notification.service.approved.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notifyapproved = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.approved.title",Stores),
  message: IntlMessageValue('de',"notification.service.approved.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für ERfolgreich gespeichert
 * @function notify_save
 * @example
 export const notify_save = () => store.addNotification({
  title: IntlMessageValue("notification.service.save.title"),
  message: IntlMessageValue("notification.service.save.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_save = (Stores:any = null) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.save.title",Stores),
  message: IntlMessageValue('de',"notification.service.save.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für gesperrte Anbieter
 * @function notify_reload_forbidden
 * @example
 export const notify_reload_forbidden = () => store.addNotification({
  title: IntlMessageValue("notification.service.reload_forbidden.title"),
  message: IntlMessageValue("notification.service.reload_forbidden.message"),
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_reload_forbidden = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.reload_forbidden.title",Stores),
  message: IntlMessageValue('de',"notification.service.reload_forbidden.message",Stores),
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für erfolgreich gespeicherte Seite nach einen setTimeout
 * @function notify_save_intvall_reload
 * @example
 export const notify_save_intvall_reload = () => store.addNotification({
  title: IntlMessageValue("notification.service.save_intvall_reload.title"),
  message: IntlMessageValue("notification.service.save_intvall_reload.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_save_intvall_reload = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.save_intvall_reload.title",Stores),
  message: IntlMessageValue('de',"notification.service.save_intvall_reload.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für erfolgreich gespeicherte Produkte
 * @function notify_saveProduct
 * @example
 export const notify_saveProduct = () => store.addNotification({
  title: IntlMessageValue("notification.service.saveProduct.title"),
  message: IntlMessageValue("notification.service.saveProduct.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_saveProduct = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.saveProduct.title",Stores),
  message: IntlMessageValue('de',"notification.service.saveProduct.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für einen Error
 * @function notify_error
 * @example
 export const notify_error = () => store.addNotification({
  title: IntlMessageValue("notification.service.error.title"),
  message: IntlMessageValue("notification.service.error.message"),
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_error = (Stores:any = null) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.error.title",Stores),
  message: IntlMessageValue('de',"notification.service.error.message",Stores),
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für eine dynamsiche Error Meldung
 * @function notify_error_Message
 * @example
 export const notify_error_Message = (text:any) => store.addNotification({
  title: IntlMessageValue("notification.service.error_Message.title"),
  message: text,
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_error_Message = (text:any,Stores:any =null) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.error_Message.title",Stores),
  message: text,
  type: "danger",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für Bildupload
 * @function notify_bucket
 * @example
 export const notify_bucket = () => store.addNotification({
  title: IntlMessageValue("notification.service.bucket.title"),
  message: IntlMessageValue("notification.service.bucket.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_bucket = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.bucket.title",Stores),
  message: IntlMessageValue('de',"notification.service.bucket.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für Bildupload error
 * @function notify_bucket_error
 * @example
 export const notify_bucket_error = (title:any,msg:any) => store.addNotification({
  title: title,
  message: msg,
  type: "warning",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 6000,
    onScreen: true
  }});
 */
export const notify_bucket_error = (title:any,msg:any) => Store.addNotification({
  title: title,
  message: msg,
  type: "warning",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 6000,
    onScreen: true
  }});

/**
 * Speichert Notification für das Kopieren einer Variante
 * @function notify_copy_variants
 * @example
 export const notify_copy_variants = (msg:any) => store.addNotification({
  title: IntlMessageValue("notification.service.copy_variants.title"),
  message: msg,
  type: "info",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 6000,
    onScreen: true
  }});
 */
export const notify_copy_variants = (msg:any,Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.copy_variants.title",Stores),
  message: msg,
  type: "info",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 6000,
    onScreen: true
  }});

/**
 * Speichert Notification für das Uploaden eines Bildes undd as anschließende Reloaden der Seite
 * @function notify_bucket_reload
 * @example
 export const notify_bucket_reload = () => store.addNotification({
  title: IntlMessageValue("notification.service.bucket_reload.title"),
  message: IntlMessageValue("notification.service.bucket_reload.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_bucket_reload = (Stores:any) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.bucket_reload.title",Stores),
  message: IntlMessageValue('de',"notification.service.bucket_reload.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

/**
 * Speichert Notification für entfernen eines Content Elements
 * @function notify_del_element
 * @example
 export const notify_del_element = () => store.addNotification({
  title: IntlMessageValue("notification.service.del_element.title"),
  message: IntlMessageValue("notification.service.del_element.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_del_element = (Stores:any  = null) => Store.addNotification({
  title: IntlMessageValue('de',"notification.service.del_element.title",Stores),
  message: IntlMessageValue('de',"notification.service.del_element.message",Stores),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});




/**
 * Speichert Notification für ERfolgreich gespeichert
 * @function notify_save
 * @example
 export const notify_save = () => store.addNotification({
  title: IntlMessageValue("notification.service.save.title"),
  message: IntlMessageValue("notification.service.save.message"),
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
 */
export const notify_token_exp = () => Store.addNotification({
  title: 'JWT Token expire' ,
  message: 'Sie sind nocht mehr angemeldet, da ihr Token abgelaufen ist.',
  type: "warning",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});

export const notify_logout = () => Store.addNotification({
  title: 'Logout' ,
  message: 'Erfolgreich ausgeloggt. Sie werden zum Login weitergeleitet',
  type: "success",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
export const notify_copy_Element_error = (Stores:any) => Store.addNotification({
  title: IntlMessageValue("","elementCopy.headline.notification",Stores),
  message: IntlMessageValue("","elementCopy.text.notification",Stores),
  type: "warning",
  insert: "top",
  container: "top-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 3000,
    onScreen: true
  }});
