import {
    ResponseHourlyBillingDetail,
    ResponseUserById
} from "../../../timetracking/api/response/timetracking.tsx";
import Table from "react-bootstrap/Table";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import Button from "react-bootstrap/Button";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";
import {dateFormat} from "../../../../../@WUM/core/component/const/dateFormat";
const HourlyBillingDetail = (props:{id:any,Store:any;inputRef:any,start:any,end:any,AllUser:any,CalculateTime:any,zeroPad:any,month:any,backYear:any,nowYear:any}) => {
    let Store = props.Store;
    const response = ResponseHourlyBillingDetail(props.id,props.start,props.end)




    const User = ResponseUserById(props.id)
    if(response !== undefined && User !== undefined && props.AllUser !== undefined) {

        let row = [];
        let sumh = 0;
        for(let x =0;x<response.length;x++){
            sumh = sumh + parseFloat(props.CalculateTime(response[x].start_time.split(' ')[1],response[x].end_time.split(' ')[1]))
            row.push(<>
                <tr>
                    <td>{dateFormat(response[x].start_time.split(' ')[0],'de','us',false,false,Store)}</td>
                    <td>{response[x].name}</td>
                    <td>{response[x].start_time.split(' ')[1].split(':')[0]+':'+response[x].start_time.split(' ')[1].split(':')[1]} - {response[x].end_time.split(' ')[1].split(':')[0]+':'+response[x].end_time.split(' ')[1].split(':')[1]}</td>
                    <td>{response[x].description}</td>
                    <td>{response[x].distance}</td>
                    <td>{props.CalculateTime(response[x].start_time.split(' ')[1],response[x].end_time.split(' ')[1])}</td>
                </tr>
            </>)
        }

        let months = [];

        for(let xs=1;xs<props.month.length;xs++){
            months.push(<>
                <option value={props.zeroPad(xs, '2')}>{props.month[xs]}</option>
            </>)
        }

        let years = [];
        for(let y=0;y<=props.backYear;y++){
            years.push(<>
                <option value={props.nowYear - y}>{props.nowYear - y}</option>
            </>)
        }



        return (
            <>

                    <div className={'whitepad'} id="divToPrint">
                        <h2><IntlMessage Store={Store} messageId={'hourlybilling.detail.header'}/>{User.name}
                            <Button href={ApiRoot()+'v2/activitiy/UserbyMonth/print/'+props.id+'_'+props.start+'_'+props.end+'/'} variant="info" style={{float:'right'}} target={'new'}><IntlMessage Store={Store} messageId={'common.button.create_a_pdf'}/></Button>

                            <br /> <label><IntlMessage Store={Store} messageId={'hourlybilling.detail.month'}/>{props.month[parseInt(props.start.split('-')[2].replace('0',''))]} </label> <label><IntlMessage Store={Store} messageId={'hourlybilling.detail.total'}/>{sumh}</label></h2>
                        <Table id={'tableContent'}>
                            <thead>
                            <tr>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.date'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.employe.zfz'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.time'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.remark'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.kilometer'}/></th>
                                <th><IntlMessage Store={Store} messageId={'common.table.head.duration'}/></th>
                            </tr>
                            </thead>
                            <tbody>
                            {row}
                            </tbody>
                        </Table>
                    </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }

}

export default HourlyBillingDetail;
