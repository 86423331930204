import {MenuItem} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import React from "react";
import MenuIcon from "./MenuIcon";
import {IntlMessageValue} from "../component/const/IntlMessage";
import TooltipModal, {TooltipIcon} from "../component/const/Tooltip";
import {ShowDialog} from "./sidebar";

const ItemMenu = (props: { items: any[], value: any, Store: any,header:any }) => {

    let Store = props.Store;
    let menuItem = [];
    if (props.items !== undefined) {
        for (let i = 0; i < props.items.length; i++) {
            if (props.items[i].icon !== undefined && props.items[i].icon !== '') {

                if(props.items[i].onClick !== undefined && props.items[i].onClick !== false) {
                    menuItem.push(<>
                        <MenuItem key={'MenuItem' + i} className={'MenuItem'}
                                  onClick={() => props.items[i].onClick(Store)}
                                  active={window.location.pathname.includes(props.items[i].route.split('/')[1]) && (props.items[i].route !== '/'|| window.location.pathname === props.items[i].route) }
                                  icon={<MenuIcon icon={(props.items[i].icon !== undefined ? props.items[i].icon : '')}/>}
                                  suffix={(process.env.REACT_APP_TOOLTIP_NAVI !== undefined ? <>
                                      <TooltipIcon Store={Store} locale={props.value} content={props.header} name={props.items[i].name} />
                                  </>: '')}>
                            {IntlMessageValue(props.value, props.items[i].name, Store)}
                        </MenuItem></>)
                } else {
                    menuItem.push(<>
                        <MenuItem key={'MenuItem' + i} className={'MenuItem'}
                                  active={window.location.pathname.includes(props.items[i].route.split('/')[1]) && (props.items[i].route !== '/'|| window.location.pathname === props.items[i].route) }
                                  icon={<MenuIcon icon={(props.items[i].icon !== undefined ? props.items[i].icon : '')}/>}
                                  suffix={(process.env.REACT_APP_TOOLTIP_NAVI !== undefined ? <>
                                      <TooltipIcon Store={Store} locale={props.value} content={props.header} name={props.items[i].name} />
                                  </>: '')}>
                            {IntlMessageValue(props.value, props.items[i].name, Store)}
                            <Link key={i} to={props.items[i].route}/>
                        </MenuItem></>)
                    }

            } else {
                menuItem.push(<>
                    <MenuItem key={'MenuItem' + i} className={'MenuItem'}>
                        {IntlMessageValue(props.value, props.items[i].name, Store)}
                        <Link key={i} to={props.items[i].route}/>
                    </MenuItem></>)
            }

        }
    }
    return (<> {menuItem} </>);
}

export default ItemMenu;


