
import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {handleSucces} from '../../../../../@WUM/core/component/const/HandleSucces';
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ResponseContactsOverview, ResponseDelete} from "../../api/response/contacts";
import {useSelector} from "react-redux";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {useState} from "react";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import {Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";

const ContactsOverview = (props:{value:any}) => {
    const {page, deleteID} = useParams<{page: string; deleteID: string}>();
    const Store = useSelector((state: any) => state)
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }


    const namespace_search = "Contacts_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let response = ResponseContactsOverview(limit,searchValue);
    const history = useNavigate();
    const destination = 'contacts';
    const routeChange = () => {
        history(-1);
    };

    if(deleteID) {
        ResponseDelete(deleteID);
        routeChange()
    }
/*    <Form.Select aria-label="Default select example" id={'type'}>
        <option></option>
        <option value={'company'}>Krankenkasse</option>
        <option value={'person'}>Person</option>
    </Form.Select>*/
    const Configuration = {
        'SearchConfig': [{
            namespace:namespace_search,
            destination: destination,
            input:[
                {id:'type',translation: 'common.type',param: 'type',method:'=', individual:<>
                        <Form.Group as={Col} controlId="typeMode">
                        <Form.Label><IntlMessage Store={Store} messageId={'common.type'}/></Form.Label>
                        <Form.Select aria-label="Default select example" id={'type'} defaultValue={localStorage.getItem('Contacts_searchValue_type') as string}>
                        <option></option>
                        <option value={'company'}>Krankenkasse</option>
                        <option value={'person'}>Person</option>
                    </Form.Select>
                        </Form.Group>
                        </>},
                {
                    id: 'status',
                    translation: 'common.status',
                    param: 'status',
                    method: '=',
                    individual: <Form.Group controlId={'status'} key={"select"} className="col" >
                        <Form.Label>Status</Form.Label>
                        <Form.Select  aria-label="Floating label select example" defaultValue={localStorage.getItem('Contacts_searchValue_status') as string}>
                            <option value={''}>Alle Kontakte</option>
                            <option value={'null'}>Inaktive Kontakte</option>
                            <option value={'1'}>Aktive Kontakte</option>
                        </Form.Select>
                    </Form.Group>
                },
                {id:'company',translation: 'common.company',param: 'company',method: 'like'},
                {id:'lastname',translation: 'common.firstname',param: 'lastname',method: 'like'},
                {id:'firstname',translation: 'common.lastname',param: 'firstname',method: 'like'}
            ],
            button: ['common.search','common.del.search','common.button.new_contacts']
        }],
        'table':{
        'tableBody': {
            'data': response,
            'dataID': 'id',
            'underdata': true,
            'keys': ['id','status', 'type','company','title', 'firstname', 'lastname',  'street','zip','phone'],
            'type': ['string','status', 'string', 'string', 'string', 'string', 'string', 'string', 'string', 'string'],
        },
        'tableHeader': [<IntlMessage Store={Store} messageId={'common.table.head.id'}/>, <IntlMessage Store={Store} messageId={'common.status'}/>,
            <IntlMessage Store={Store} messageId={'common.type'}/>, <IntlMessage Store={Store} messageId={'common.company'}/>,
            <IntlMessage Store={Store} messageId={'common.title'}/>, <IntlMessage Store={Store} messageId={'common.firstname'}/>,
            <IntlMessage Store={Store} messageId={'common.lastname'}/>,<IntlMessage Store={Store} messageId={'common.street'}/>,
            <IntlMessage Store={Store} messageId={'common.zip'}/>,<IntlMessage Store={Store} messageId={'common.phone'}/>],
        'tableHeaderWidth': ['5%', '10%', '10%', '15%','5%','10%', '10%', '15%','10%','10%'],
        'tablecolor': ['light'],
        'menu': {
            'data': response,
            'menuDisable': false,
            'menutriggerDelete': true,
            'menuItem': [<IntlMessage Store={Store} messageId="common.edit" />, <IntlMessage Store={Store} messageId="common.delete" />],
            'menuroute': ['/contacts/Detail', '/contacts/Delete/1'],
            'menuParams': ['','id', 'id'],
            'menuIcons': ['pencil', 'Delete'],
        },
    }
    };
    if (response.length > 0) {
    return (
      <>
          <div className={'site'}>
              <SearchJSX setSearchValue={setSearchValue} Store={Store} SearchConfig={Configuration['SearchConfig']} />
              <br style={{clear:'both'}} />
          <PielersTable Configuration={Configuration['table']} />
          <PaginationJSX response={response} history={history} to={'overview'} handleSuccess={handleSucces} destination={destination} />
          </div>

      </>
    );
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default ContactsOverview;
