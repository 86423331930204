import React from 'react'
import IntlMessage, {
    IntlMessageValue
} from '../../../../../../core/component/const/IntlMessage'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any
) {
    const titel = (
        <>
            <h2>FAQ Informationen</h2>
        </>
    )

    const description = (
        <>
            <p>
                Tragen Sie hier die Informationen zu Ihren FAQ ein. Beachten
                Sie, die Informationstexte.
            </p>
        </>
    )

    const dataColOne = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'id',
            value: newElement === false ? response.id : ''
        },
        {
            formType: 'input',
            label: 'Name',
            required: true,
            type: 'text',
            placeholder: 'Bitte den FAQ Namen eingeben',
            legende:
                'Hier bitte den Namen für das FAQ angeben. Wie er in der Liste ausgespielt wird',
            name: 'name',
            value: newElement === false ? response.name : ''
        },
        {
            formType: 'input',
            type: 'text',
            label: 'Locale',
            name: 'locale',
            required: true,
            placeholder: 'Bitte geben Sie das Länderkürzel ein.',
            legende:
                'Hier bitte das Länderkürzel eingeben für die die Übersetzung ist',
            value: newElement === false ? response.locale : 'de'
        },
        {
            formType: 'input',
            type: 'number',
            label: 'Category ID',
            required: true,
            name: 'cat_id',
            placeholder: 'Bitte geben Sie die Category ID ein',
            legende:
                'Hier bitte die Category ID eingeben für die das neue FAQ ist oder eine neue.',
            value: newElement === false ? response.cat_id : ''
        },
        {
            formType: 'select',
            label: <IntlMessage Store={Store} messageId="common.status" />,
            name: 'status',
            legende:
                'Hier bitte auswählen ob der Artikel aktiviert oder deaktiviert ist!',
            selected: newElement === false ? response.status : 0,
            options: [
                {
                    label: IntlMessageValue('de', 'common.activated', Store),
                    value: '1'
                },
                {
                    label: IntlMessageValue('de', 'common.deactivated', Store),
                    value: '0'
                }
            ]
        }
    ]

    const dataColtwo = [
        {
            formType: 'input',
            type: 'text',
            label: 'Description',
            name: 'description',
            placeholder: 'Bitte die FAQ Description eingeben',
            legende: 'Hier bitte die Description für das FAQ angeben.',
            value: newElement === false ? response.description : ''
        },
        {
            formType: 'input',
            type: 'text',
            label: 'Type',
            name: 'type',
            required: true,
            legende:
                'Hier bitte den Type auswählen den das FAQ angehört. Standart ist immer `all`',
            placeholder: 'Bitte wählen Sie den Type aus',

            value: newElement === false ? response.type : 'all'
        },
        {
            formType: 'input',
            type: 'text',
            label: 'Parent',
            name: 'Parent',
            legende:
                'Hier bitte falls das FAQ ein unter FAQ einen anderen Category ist auswählen.',
            placeholder: 'Bitte wählen Sie den Parent aus',
            value: newElement === false ? response.parent : ''
        }
    ]

    if (conf === 'titel') {
        return titel
    } else if (conf === 'description') {
        return description
    } else if (conf === 'dataColOne') {
        return dataColOne
    } else if (conf === 'dataColtwo') {
        return dataColtwo
    }
}

export default configuration
