import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import '../css/dashboard.css'
import {useSelector} from "react-redux";
import IntlMessage from "../../../../@WUM/core/component/const/IntlMessage";
import Cards from "../../../../@WUM/core/component/const/dashboardCards";

const DashbaordOverview = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)
    if(localStorage.getItem('userRole') as string === 'superadmin' || localStorage.getItem('userRole') as string === '1') {
    return (<>
    <Row >
        <Col className={'DashbaordRow'}>
            <h3><IntlMessage Store={Store} messageId={'common.header.customer'}/></h3>
            <Row >
                <Col><Cards title={'dashboard.care_services_by_year.header'} icon={'receipt'} href={'/CareServiceSettlement/overview/1'} text={'dashboard.care_services_by_year.description'} Store={Store} /></Col>
                <Col><Cards title={'dashboard.customer_bills_per_month.header'} icon={'receipt'} href={'/customerSettlement/overview/1'} text={'dashboard.customer_bills_per_month.description'} Store={Store} /> </Col>
            </Row>
           {/* <Row >
                <Col><Cards title={'Infoliste'} href={'#'} /></Col>
                <Col><Cards title={'dashboard.declaration_of_resignation.header'} href={'#'} text={'dashboard.declaration_of_resignation.description'} Store={Store} /></Col>
                <Col><Cards title={'dashboard.additional_care_services.header'} href={'#'} text={'dashboard.additional_care_services.description'} Store={Store} /></Col>
            </Row>*/}
            <Row >
                {/*<Col><Cards title={'Zusätzliche Betreuungsleistungen'} href={'#'} /></Col>*/}
                <Col></Col>
            </Row>
        </Col>
        <Col className={'DashbaordRow'}>
            <h3><IntlMessage Store={Store} messageId={'common.header.employe'}/></h3>
            <Row >
                <Row>
                    <Col><Cards title={'dashboard.hourlybilling.header'} icon={'receipt_long'}   href={'/hourlybilling/overview/1'} text={'dashboard.hourlybilling.description'} Store={Store} /></Col>
                    <Col><Cards title={'dashboard.timetracking.header'} icon={'work_history'}   href={'/timetracking/overview/1'} text={'dashboard.timetracking.description'} Store={Store} /></Col>
                </Row>
            </Row>
        </Col>
    </Row>
    </>)
}else {
        return (<>
            <Row >
                <Col className={'DashbaordRow'}>
                    <h3><IntlMessage Store={Store} messageId={'common.header.employe'}/></h3>
                    <Row >
                        <Row>
                            <Col><Cards title={'dashboard.timetracking.header'} icon={'work_history'}   href={'/timetracking/overview/1'} text={'dashboard.timetracking.description'} Store={Store} /></Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </>)
    }
}

export default DashbaordOverview;


