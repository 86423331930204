import { useState, useEffect } from "react";

/**
 * Gibt eine localStorage zurück
 * @param key
 * @param defaultValue
 */
function getStorageValue(key:any, defaultValue:any) {
  // getting stored value
  const saved = localStorage.getItem(key);
  // @ts-ignore
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

/**
 * Benutzt eine Localstoreage mittels UseState
 * @param key
 * @param defaultValue
 */
export const useLocalStorage = (key:any, defaultValue:any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

/**
 * Entfernt eine localStoreage
 * @param key
 */
export const removeUseLocalStorage = (key:any) => {
  localStorage.removeItem(key);

}