
import updateDataMappingProps from "../@WUM/core/component/core/interface/updateDataMappingProps.interface";

const updateDataMapping = (props:updateDataMappingProps) => {

    switch (props.part) {
        default:
            return;
    }
}

export default updateDataMapping;
