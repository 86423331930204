import {useNavigate, useParams} from "react-router-dom";
import {Form, Tab, Tabs} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import {
    ResponseAllCustomer,
    ResponseAllUSer,
    ResponseTimetrackingDetail
} from "../../api/response/timetracking.tsx";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import {ResponseBudgetbyCustomer} from "../../../../settings/customer/api/response/Customer";
import {apiRoot, saveDataIndividualGet} from "../../../../../@WUM/core/RestFullApi/ApiHook";
import {useSelector} from "react-redux";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {notify_save} from "../../../../../@WUM/core/component/const/notification.service";

const TimeTrackingDetail = (props:{value:any}) => {

    let {id} = useParams<{id: string}>();
    const Store = useSelector((state: any) => state)
    const response = ResponseTimetrackingDetail(id)
    const history = useNavigate();
    const AllUser = ResponseAllUSer()
    const Budget = ResponseBudgetbyCustomer((response !== undefined ? response.budget_ID: 0));
    const ALLCustomer = ResponseAllCustomer();


    const switchBudget = (uid:any) => {
        saveDataIndividualGet(`/v2/budget/byuser/${uid}/`).then((data: any) => {
            console.log(data)
            let select = document.getElementById('formGridBudget') as HTMLSelectElement;
            select.innerHTML = '';
            for(let x=0;x<data.length;x++){
                var option = document.createElement("option");
                option.innerHTML = data[x].budgetname;
                option.value = data[x].id;
                select.appendChild(option)
            }
        })
            .catch((e) => {

            })
    }

    const save = () => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Bearer ' + JSON.parse(localStorage.getItem('token') as string)+"");

        var raw = JSON.stringify({
            "id": ((document.getElementById('id') as HTMLInputElement).value !== '' ? (document.getElementById('id') as HTMLInputElement).value : null),
            "customer_ID":((document.getElementById('customer_ID') as HTMLSelectElement).value  !== '' ? (document.getElementById('customer_ID') as HTMLSelectElement).value  : null),
            "user_ID":((document.getElementById('user_ID') as HTMLSelectElement).value  !== '' ? (document.getElementById('user_ID') as HTMLSelectElement).value  : null),
            "start_time":((document.getElementById('start_time') as HTMLInputElement).value !== '' ? (document.getElementById('date') as HTMLInputElement).value+' '+(document.getElementById('start_time') as HTMLInputElement).value : null),
            "end_time": ((document.getElementById('end_time') as HTMLInputElement).value !== '' ? (document.getElementById('date') as HTMLInputElement).value+' '+(document.getElementById('end_time') as HTMLInputElement).value : null),
            "journey_fee":0,
            "distance": ((document.getElementById('distance') as HTMLInputElement).value  !== '' ? (document.getElementById('distance') as HTMLInputElement).value  : null),
            "description":  ((document.getElementById('description') as HTMLTextAreaElement).value  !== '' ? (document.getElementById('description') as HTMLTextAreaElement).value  : null),
            "budget_ID":((document.getElementById('budget_ID') as HTMLSelectElement).value  !== '' ? (document.getElementById('budget_ID') as HTMLSelectElement).value  : null),
            "invoice_ID":null,
            "break_start": ((document.getElementById('break_start') as HTMLInputElement).value !== '' ? (document.getElementById('break_start') as HTMLInputElement).value : null),
            "break_end": ((document.getElementById('break_end') as HTMLInputElement).value !== '' ? (document.getElementById('break_end') as HTMLInputElement).value : null),
            "status": ((document.getElementById('status') as HTMLInputElement).value !== '' ? (document.getElementById('status') as HTMLInputElement).value : 0),
        })



        var requestOptions = {
            method: 'POST',
            body: raw,
            headers: myHeaders
        };
        let button = (document.getElementById("btn_save") as HTMLButtonElement);

        button.setAttribute("disabled", "disabled");
        fetch(apiRoot+"v2/activitiy/0/", requestOptions)
            .then(response => response.text())
            .then(result => rewrite())
            .catch(error => errorEvent(error));


    }

    const rewrite = () => {

        notify_save(Store);
        let button = (document.getElementById("btn_save") as HTMLButtonElement);

        button.removeAttribute("disabled");
    }

    const errorEvent = (error:any) => {
        console.log('error', error)
        let button = (document.getElementById("btn_save") as HTMLButtonElement);

        button.removeAttribute("disabled");
    }

    if(response !== undefined && AllUser !== undefined && ALLCustomer !== undefined && Budget !== undefined) {

        return (
            <>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" key={'tabindex'}>
                    <Tab eventKey="home" title={IntlMessageValue('de','dashboard.timetracking.header',Store)} key={'allgemein'} id={'allgemein'}
                         style={{backgroundColor: "White", padding: "15px", marginTop: "-15px"}}>
                        <Form>
                            <Row className="mb-3">
                                <input type={'hidden'} id={'id'} value={response.id} />
                                <Form.Group as={Col} controlId="date">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.date'}/></Form.Label>
                                    <Form.Control type="date" placeholder="Bitte Datum eingeben" defaultValue={response.start_time.split(' ')[0]}/>
                                </Form.Group>
                                <FetchSelect Data={ALLCustomer} id={'id'} controlId={'customer_ID'} value={'lastname'} value2={'firstname'} Uid={response.customer.id} label={IntlMessageValue('de','common.table.head.employe',Store)} onChanges={switchBudget} />
                                <FetchSelect Data={AllUser} id={'id'} controlId={'user_ID'}  value={'name'} Uid={response.user.id} label={IntlMessageValue('de','common.employe',Store)} />

                                <Form.Group as={Col} controlId="distance">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.driven_km'}/></Form.Label>
                                    <Form.Control type={"number"}  defaultValue={response.distance}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="mb-3" controlId="start_time">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.time_start'}/></Form.Label>
                                    <Form.Control type="time"  defaultValue={response.start_time.split(' ')[1]}/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="break_start">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.breack_from'}/>:</Form.Label>
                                    <Form.Control type="time"  defaultValue={response.pause_start}/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="break_end">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.breack_until'}/>:</Form.Label>
                                    <Form.Control type="time"  defaultValue={response.pause_stop}/>
                                </Form.Group>

                                <Form.Group as={Col} className="mb-3" controlId="end_time">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.time_end'}/></Form.Label>
                                    <Form.Control type="time"  defaultValue={response.end_time.split(' ')[1]}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="description">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.table.head.remark'}/></Form.Label>
                                    <Form.Control as="textarea" rows={3} defaultValue={response.description}/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                            <FetchSelect Data={Budget} id={'id'} controlId={'budget_ID'} value={'budgetname'} Uid={response.budget_ID} label={'Budget'} asCol={false} />
                                <Form.Group as={Col} controlId="status">
                                    <Form.Label><IntlMessage Store={Store} messageId={'common.status'}/></Form.Label>
                                    <Form.Select aria-label="Default select example" defaultValue={response.status}>
                                        <option></option>
                                        <option value={1}>Aktiviert</option>
                                        <option value={0}>Deaktiviert</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <br />
                            {/*<Form.Group className="mb-3" id="formGridcheck">
                                <Form.Check type="checkbox"
                                            label="Ich versichere das alle Angaben korreckt und geprüft sind"/>
                            </Form.Group>*/}
                            <hr />
                            <Button variant='light' style={{float: 'left', marginLeft: '15px'}} onClick={() => history(-1)}>
                                <IntlMessage Store={Store} messageId={'common.back'}/>
                            </Button>

                            <Button variant='success'
                                    style={{float: 'right', marginRight: '15px'}}
                                    type={'button'}
                                    id={'btn_save'}
                                    onClick={()=>save()}
                            >
                                <IntlMessage Store={Store} messageId={'common.save'}/>
                            </Button>
                            <br style={{clear: 'both'}} />
                        </Form>
                    </Tab>
                </Tabs>

            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store} /></>
        );
    }
}

export default TimeTrackingDetail;
