import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React from "react";
import TabsBuilderProps from "../interface/TabsBuilderProps.interface";


const TabsBuilder = (props:TabsBuilderProps) => {

    let config = props.config[0];

    let tabs: JSX.Element[] = [];
    for(let x = 0; x< config.tabs.length;x++){
        let nowTab = config.tabs[x];
        tabs.push(
            <Tab eventKey={nowTab.eventKey} title={nowTab.title} key={nowTab.key} id={nowTab.id} className={nowTab.class}>
                {nowTab.content}
            </Tab>
        )
    }


    return(
        <>
            <Tabs defaultActiveKey={config.tab.defaultActiveKey} id={config.tab.id} className={config.tab.className} key={config.tab.key}>
                {tabs}
            </Tabs>
        </>
    )

}

export default TabsBuilder;