import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import IntlMessage from "./IntlMessage";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import React from "react";
import { useNavigate } from 'react-router-dom';


const SearchJSX = (props: { setSearchValue: any, noNewButton?:any,secondNewButton?:any,secondNewButtonUrl?:any, Store: any, SearchConfig: { input: ({type?:any, individual?: any, underlineCaption?:any, translation: string, id: string, method: string, param: string })[]; destination: string; button: string[]; namespace: string }[] }) => {
    const navigate = useNavigate();
    const location = window.location.pathname.split('/')
    const  forward = window.location.pathname.replace(location[window.location.pathname.split('/').length -1],'1')
    const clearSearch = () => {

        localStorage.setItem(props.SearchConfig[0].namespace, '');
        for (let x = 0; x < props.SearchConfig[0].input.length; x++) {
            localStorage.setItem(props.SearchConfig[0].namespace + '_' + props.SearchConfig[0].input[x].id, '');
            (document.getElementById(props.SearchConfig[0].input[x].id) as HTMLSelectElement).value = '';
        }
        props.setSearchValue('')
        navigate(forward)
    }

    const _handleKeyFilters = function (e: any) {
        if (e.target.value !== '0') {

            let search = '';

            let iterator = [];

            let holder;
            for (let x = 0; x < props.SearchConfig[0].input.length; x++) {
                holder = (document.getElementById(props.SearchConfig[0].input[x].id) as HTMLSelectElement).value;
                localStorage.setItem(props.SearchConfig[0].namespace + '_' + props.SearchConfig[0].input[x].id, holder);
                if (holder !== undefined && holder !== '') {
                    switch (props.SearchConfig[0].input[x].method) {
                        case 'like':
                            iterator.push(props.SearchConfig[0].input[x].param + "= like '%_" + holder + "%'");
                            break;
                        case 'first_like':
                            iterator.push(props.SearchConfig[0].input[x].param + "= like '%_" + holder + "'");
                            break;
                        case 'end_like':
                            iterator.push(props.SearchConfig[0].input[x].param + "= like '" + holder + "%'");
                            break;
                        case 'like_with_start':
                            iterator.push(props.SearchConfig[0].input[x].param + "= like with start '" + holder + "%'");
                            break;
                        case '=':
                            iterator.push(props.SearchConfig[0].input[x].param + "=" + holder + "");
                            break;
                        case 'not like':
                            iterator.push(props.SearchConfig[0].input[x].param + "= not like '%_" + holder + "%'");
                            break;
                        case '<':
                            iterator.push(props.SearchConfig[0].input[x].param + "= <" + holder + "");
                            break;
                        case '<>':
                            iterator.push(props.SearchConfig[0].input[x].param + "= <>" + holder + "");
                            break;
                        case '>':
                            iterator.push(props.SearchConfig[0].input[x].param + "= >" + holder + "");
                            break;
                        case '!=':
                            iterator.push(props.SearchConfig[0].input[x].param + "= !=" + holder + "");
                            break;
                        case 'not in':
                            iterator.push(props.SearchConfig[0].input[x].param + "= not in " + holder + "");
                            break;
                        case 'in':
                            iterator.push(props.SearchConfig[0].input[x].param + "= in" + holder + "");
                            break;
                    }
                }
            }

            for (let y = 0; y < iterator.length; y++) {
                //console.log(iterator)
                if (y === 0) {
                    search += '?' + iterator[y];
                } else {
                    search += '&' + iterator[y];
                }
            }
            //console.log(search)
            props.setSearchValue(search);
            localStorage.setItem(props.SearchConfig[0].namespace, search);
        }
        navigate(forward)
    };


    let rowInput = [];

    let underlineCaption = (<></>);



    for (let x = 0; x < props.SearchConfig[0].input.length; x++) {
        if(props.SearchConfig[0].input[x].underlineCaption !== undefined) {
            underlineCaption = (<>
                <Form.Text className="text-muted searchPageInfo">
                    <IntlMessage Store={props.Store} messageId={props.SearchConfig[0].input[x].underlineCaption}/>
                </Form.Text>
            </>)
        }
        if (props.SearchConfig[0].input[x].individual !== undefined) {
            rowInput.push(<>{props.SearchConfig[0].input[x].individual}</>)
        } else {
            rowInput.push(<>
                <Form.Group as={Col} controlId={props.SearchConfig[0].input[x].id}
                            name={props.SearchConfig[0].input[x].id}>
                    <Form.Label><IntlMessage Store={props.Store}
                                             messageId={props.SearchConfig[0].input[x].translation}/></Form.Label>
                    <Form.Control type={(props.SearchConfig[0].input[x].type !== undefined ? props.SearchConfig[0].input[x].type : 'text')}
                                  defaultValue={(localStorage.getItem(props.SearchConfig[0].namespace + '_' + props.SearchConfig[0].input[x].id) as string)}/>
                    {underlineCaption}
                </Form.Group>
            </>)
        }
    }

    let newButton = (<>
        <div className="col">
            <div style={{position:'absolute',bottom:'0',right:'10px'}}>
                <Button variant={'success'} href={'/' + props.SearchConfig[0].destination + '/new/1'}
                        style={{float: 'right'}}>
                    <IntlMessage Store={props.Store} messageId={props.SearchConfig[0].button[2]}/>
                </Button>
            </div>
        </div>
    </>)

    if(props.noNewButton !== undefined) {
        newButton = (<></>)
    }

    let secondnewButton = (<></>)

    if(props.secondNewButton !== undefined && props.secondNewButtonUrl !== undefined) {
        secondnewButton =(<>
            <div className="col">
                <div style={{position:'absolute',bottom:'0',right:'10px'}}>
                    <Button variant={'success'} href={'/' + props.SearchConfig[0].destination + props.secondNewButtonUrl}
                            style={{float: 'right'}}>
                        <IntlMessage Store={props.Store} messageId={props.SearchConfig[0].button[3]}/>
                    </Button>
                </div>
            </div>
        </>)
    }

    return (<>
        <div style={{backgroundColor: 'white', padding: ' 15px'}}>

            <Row className="mb-3">
                {rowInput}
                <Form.Group as={Col} controlId="searchCointainer">
                    <div style={{position:'absolute',bottom:'0'}}>
                    <Button variant={'danger'} onClick={(e) => clearSearch()} style={{float: 'left', marginRight: '10px'}}>
                        <IntlMessage Store={props.Store} messageId={props.SearchConfig[0].button[1]}/>
                    </Button>
                    <Button variant={'success'} onClick={(e) => _handleKeyFilters(e)}
                            style={{float: 'left'}}>
                        <IntlMessage Store={props.Store} messageId={props.SearchConfig[0].button[0]}/>
                    </Button>
                    </div>
                </Form.Group>
                {newButton}
                {secondnewButton}
            </Row>

        </div>
    </>)

}

export default SearchJSX;
