import {requests} from "../../../../../@WUM/core/RestFullApi/api";
import {timetrackingApiDetailType} from "./timetrackingApiDetailType";
import {UserApiDetailType} from "./UserApiDetailType";
import {incoiceMonthlyApiDetail} from "./incoiceMonthlyApiDetail";
import {invoiceByUserYearPeeviewApiDetailType} from "./invoiceByUserYearPeeviewApiDetailType";

/**
 * Sends the call to the FULL REST API with the methods GET, POST, PUT
 * @namespace example
 * @see {@link siteApiType}
 * @prop {any} limit Indicates the current page
 * @prop {any} searchValue Submits search filters
 * @prop {any} post Indicates POST
 * @prop {any} id Transmits the ID of the target element
 * @example
 * getPosts: (limit: any, searchValue: any): Promise<blogApiType[]> => requests.get(`Content/Blog/${limit}/${searchValue}`),
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
export const timetracking = {
    getToken: (post: any): Promise<timetrackingApiDetailType> =>
        requests.post(`/Auth/token/`, post),
    getAPost: (limit:any,searchValue:any): Promise<timetrackingApiDetailType[]> =>  requests.get(`/v2/activities/All/${limit}/${searchValue}`),
    getAPostDetail: (id: string): Promise<timetrackingApiDetailType> =>  requests.get(`/v2/activitiy/${id}/`),
    // HourlyBilling
    getAPostHourlyBillingDetail: (id: string,start:string,ende:string): Promise<timetrackingApiDetailType[]> =>  requests.get(`/v2/activitiy/UserbyMonth/${id}_${start}_${ende}/`),
    getAPostHourlyBillingDetailFile: (id: string,start:string,ende:string): Promise<incoiceMonthlyApiDetail[][]> =>  requests.get(`/v2/invoice/monthly/preview/${start}_${ende}/`),
    getAPostinvoiceByUserYearPreview: (id: string,year:string): Promise<invoiceByUserYearPeeviewApiDetailType[][]> =>  requests.get(`/v2/invoice/preview/year/${id}_${year}/`),
    getAPostUserById: (id: string): Promise<UserApiDetailType> =>  requests.get(`/v2/user/${id}/`),
    getAPostAllUSer: (): Promise<UserApiDetailType[]> =>  requests.get(`/v2/user/All/`),
    getAPostAllCustomer: (): Promise<UserApiDetailType[]> =>  requests.get(`/v2/customer/Find/All/`),
    getAPostBudget: (limit:any,searchValue:any): Promise<UserApiDetailType[]> =>  requests.get(`/v2/budgets/All/${limit}/${searchValue}`),
    deletePostBudget: (id: string): Promise<void> => requests.delete(`/v2/budget/delete/${id}/`),
    deletePostTimetracking: (id: string): Promise<void> => requests.delete(`/v2/activitiy/delete/${id}/`),
    getAPostHourlyBillinginvoicePdf: (id:any,start:any,ende:any,budget:any,invoice:any = 0): Promise<incoiceMonthlyApiDetail[][]> =>  requests.get(`/v2/invoice/pdf/${id}/${start}/${ende}/${budget}/${invoice}/`),
};
