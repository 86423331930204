import React from 'react'
import IntlMessage, {
    IntlMessageValue
} from '../../../../../../core/component/const/IntlMessage'

function configuration(
    conf: string,
    response: any,
    newElement: any = false,
    Store: any,
    index: any
) {
    const config = [
        {
            formType: 'input',
            type: 'hidden',
            name: 'posts[' + index + '][id]',
            value: newElement === false ? response.id : '0'
        },
        {
            formType: 'multiply',
            cols: [
                {
                    formType: 'input',
                    type: 'text',
                    label: 'Titel',
                    name: 'posts[' + index + '][title]',
                    required: true,
                    placeholder: 'Bitte geben Sie den Title des Artikel ein.',
                    legende:
                        'Hier bitte eingeben wie der Titel des Artikel ist wie er gefunden werden soll.',
                    value: newElement === false ? response.title : ''
                },
                {
                    formType: 'input',
                    type: 'text',
                    label: 'Locale',
                    name: 'posts[' + index + '][locale]',
                    required: true,
                    placeholder: 'Bitte geben Sie das Länderkürzel ein.',
                    legende:
                        'Hier bitte das Länderkürzel eingeben für die die Übersetzung ist',
                    value: newElement === false ? response.locale : 'de'
                },
                {
                    formType: 'select',
                    label: (
                        <IntlMessage Store={Store} messageId="common.status" />
                    ),
                    name: 'posts[' + index + '][status]',
                    legende:
                        'Hier bitte auswählen ob der Artikel aktiviert oder deaktiviert ist!',
                    selected: newElement === false ? response.status : 0,
                    options: [
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.activated',
                                Store
                            ),
                            value: '1'
                        },
                        {
                            label: IntlMessageValue(
                                'de',
                                'common.deactivated',
                                Store
                            ),
                            value: '0'
                        }
                    ]
                }
            ]
        },
        {
            formType: 'suneditor',
            data: {},
            name: 'posts[' + index + '][content]',
            jsonID: '',
            id: '',
            appendJson: '',
            appendJsonField: '',
            label: 'Artikel eingeben!',
            value: newElement === false ? response.content : ''
        },
        {
            formType: 'divider'
        }
    ]

    return config
}

export default configuration
