import TableCell from "@material-ui/core/TableCell";
import React from "react";


export const usergroupMHP = (text: any, id: any, index: number, VisitData: any, VisitDataDel: any, Store: any, tableBodyPositionElement: any) => {

    let user;
    if(text === 1){
        user = 'Administrator';
    } else {
        user = 'User'
    }

    return (
        <><TableCell style={{textAlign:tableBodyPositionElement}}  key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}>
            {user}
        </TableCell>
        </>
    );
}
