import {
    ResponseHourlyBillingDetailFile
} from "../../../../hour/timetracking/api/response/timetracking.tsx";
import Table from "react-bootstrap/Table";
import {SpinnerJSX} from "../../../../../@WUM/core/component/const/Spinner";
import Button from "react-bootstrap/Button";
import {ApiRoot} from "../../../../../@WUM/core/RestFullApi/api";
import {isEmptyArray} from "formik";
import differenceTwoTime from "../../../../../@WUM/core/component/const/differenceTwoTime";
import IntlMessage from "../../../../../@WUM/core/component/const/IntlMessage";

const CustomerSettlementDetail = (props: { id: any, Store: any; year: any, inputRef: any, start: any, end: any, AllUser: any, CalculateTime: any, zeroPad: any, month: any, backYear: any, nowYear: any }) => {
    let Store = props.Store;
    const response = ResponseHourlyBillingDetailFile(props.id, props.start, props.end)

    if (response !== undefined) {


        let row = [];
        for (let x = 0; x < response[0].length; x++) {
            row.push(<>
                    <tr style={{border:'none'}}>
                        <td style={{border:'none'}}>{response[0][x].customer.firstname} {response[0][x].customer.lastname}:</td>
                        {buildActivity(response[0][x].customer.activitiy,Store)}
                    </tr>

            </>)
        }

        let months = [];

        for (let xs = 1; xs < props.month.length; xs++) {
            months.push(<>
                <option value={props.zeroPad(xs, '2')}>{props.month[xs]}</option>
            </>)
        }

        let years = [];
        for (let y = 0; y <= props.backYear; y++) {
            years.push(<>
                <option value={props.nowYear - y}>{props.nowYear - y}</option>
            </>)
        }


        return (
            <>

                <div className={'whitepad'} id="divToPrint">
                    <h2><IntlMessage Store={Store} messageId={'customerSettlement.detail.header'}/>{props.month[parseInt(props.start.split('-')[1].replace('0', ''))]} {props.year}
                        <Button href={ApiRoot() + 'v2/invoice/monthly/' + props.start + '_' + props.end + '/'}
                                variant="info" style={{float: 'right'}} target={'new'}><IntlMessage Store={Store} messageId={'common.button.create_a_pdf'}/></Button>

                    </h2>
                    <Table id={'tableContent'} bordered={false}>
                        {row}
                    </Table>
                </div>
            </>
        )
    } else {
        return (
            <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
        );
    }

}

export default CustomerSettlementDetail;

const buildActivity = (activity:any,Store:any) => {

    if(isEmptyArray((activity))) {
        return (<><td style={{border:'none'}} colSpan={2}><strong><IntlMessage Store={Store} messageId={'customerSettlement.detail.no_care'}/></strong></td></>)
    }

    let time = [];

    let sumtime = 0;

    let ma = [];

    let km = [];

    let sumkm = 0;



    for(let x=0;x<activity.length;x++) {
    if(activity[x] !== undefined) {
        let description = (<></>)
        let descriptiongeneral = (<></>)
        if(activity[x].description !== null && activity[x].description !== '' && activity[x].description !== undefined){
            description = (<><tr style={{border:'none'}}>
                <td style={{border:'none'}}><i>{activity[x].description}</i></td>
            </tr></>)
            descriptiongeneral = (<><tr style={{border:'none'}}>
                <td style={{border:'none'}}><br/></td>
            </tr></>)
        }
        sumtime = sumtime + differenceTwoTime(activity[x].end_time,activity[x].start_time)
            time.push(<>
                {description}
                <tr style={{border:'none'}}>
                    <td style={{border:'none'}}>
                        <strong>{activity[x].start_time.split('-')[2].split(' ')[0]}.{activity[x].start_time.split('-')[1]}. </strong> {activity[x].start_time.split(' ')[1]} - {activity[x].end_time.split(' ')[1]} = {differenceTwoTime(activity[x].end_time,activity[x].start_time)} Std.
                    </td>
                </tr>
            </>)

            km.push(<>
                {descriptiongeneral}
                <tr style={{border:'none'}}>
                    <td style={{border:'none'}}>{activity[x].distance} km</td>
                </tr>
            </>)

        ma.push(<>
            {descriptiongeneral}
            <tr style={{border:'none'}}>
                <td style={{border:'none'}}>{activity[x].username}</td>
            </tr>
        </>)

        sumkm =sumkm+activity[x].distance
        }
    }





    return (
        <>
            <td style={{border:'none'}}>

                {time}
                <tr style={{border:'none'}}>
                    <td style={{border:'none'}}><strong><IntlMessage Store={Store} messageId={'customerSettlement.detail.total'}/>{sumtime} <IntlMessage Store={Store} messageId={'customerSettlement.detail.hour_short'}/></strong></td>
                </tr>
            </td>
            <td style={{border:'none'}}>

                {km}
                <tr style={{border:'none'}}>
                    <td style={{border:'none'}}><strong>{sumkm} km</strong></td>
                </tr>

            </td>
            <td style={{border:'none'}}>
                {ma}
            </td>

        </>
    )

}
