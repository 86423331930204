import React from 'react';
import dateCut from './dateCut';
import TableCell from '@material-ui/core/TableCell';
function dateFormateDE(text: string, id: any, index: number, VisitData: any, VisitDataDel: any, Store: any, tableBodyPositionElement: any){


  return (
    <><TableCell key={"td"+id+ "_" +index} data-param={VisitData} data-param-del={VisitDataDel} data-id={id} id={"td"+id+ "_" +index}
                 style={{textAlign:tableBodyPositionElement}}>
      {formatDate(text,id,index,VisitData,VisitDataDel,Store)}
    </TableCell>
    </>
  );

}

export default dateFormateDE;

function formatDate(dateString:any, id: any, index: number,VisitData:any, VisitDataDel: any,Store:any)
{

  if(dateString !== '' && dateString !== null && dateString !== undefined) {

    let thisDate = dateString?.split('-');
    let newDate = [thisDate[2], thisDate[1], thisDate[0]].join(".");

     return newDate ;

    //return newDate;
  } else {
    return '--unbekannt--';
  }
}
