import {RegisterRoute} from "../../../../Register/RegisterRoute";
import {HelpDeskPagesConfig} from "../../../helpdesk/modules/register";

const RoutingConfig = (value: any) => {

    if(process.env.REACT_APP_HELPDESK_EDIT !== undefined && process.env.REACT_APP_HELPDESK_EDIT === 'true') {
        return [
            ...RegisterRoute(value),
            ...HelpDeskPagesConfig(value)
        ];
    } else {
        return [
            ...RegisterRoute(value)
        ];
    }



}

export default RoutingConfig;
