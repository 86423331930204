import deTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/de_DE.json";
import de from './Language/de_DE.json'

import enTicketsystemMessages from "../modules/ticketsystem/modules/localization/locales/en_US.json";
import en from './Language/en_EN.json'

export const RegisterLanguageDE = {
    ...de,
    ...deTicketsystemMessages
}

export const RegisterLanguageEN = {
    ...en,
    ...enTicketsystemMessages
}
