import PielersTable from '../../../../../@WUM/core/tablemaker';
import {useNavigate,useParams} from "react-router-dom";
import {handleSucces} from '../../../../../@WUM/core/component/const/HandleSucces';
import PaginationJSX from '../../../../../@WUM/core/form-serilizer/elements/pagination';
import {SpinnerJSX} from '../../../../../@WUM/core/component/const/Spinner';
import {ResponseAllCustomer, ResponseTimetrackingOverview} from "../../api/response/timetracking.tsx";
import {useSelector} from "react-redux";
import IntlMessage, {IntlMessageValue} from "../../../../../@WUM/core/component/const/IntlMessage";
import {useState} from "react";
import FetchSelect from "../../../../../@WUM/core/component/const/Fetchselect";
import SearchJSX from "../../../../../@WUM/core/component/const/SearchJSX";
import {Form} from "react-bootstrap";

const TimeTrackingOverview = (props:{value:any}) => {
    const Store = useSelector((state: any) => state)

    const {page} = useParams<{ page: string; }>();
    let limit: any;
    if (typeof page === "string") {
        limit = (parseFloat(page) * 10) - 10;
    }
    const history = useNavigate();
    const destination = 'timetracking';


    const namespace_search = "TimeTracking_searchValue";
    const [searchValue, setSearchValue] = useState((localStorage.getItem(namespace_search) !== null ? localStorage.getItem(namespace_search) : ''));
    let response = ResponseTimetrackingOverview(limit, searchValue)
    const ALLCustomer = ResponseAllCustomer();

        const Configuration = {
            'SearchConfig': [{
                namespace: namespace_search,
                destination: destination,
                input: [
                    {
                        id: 'kunde',
                        translation: 'common.firstname',
                        param: 'name',
                        method: '=',
                        individual: <FetchSelect Data={ALLCustomer} id={'lastname'} id2={'firstname'}
                                                 controlId={'kunde'} value={'lastname'} value2={'firstname'} Uid={0}
                                                 label={IntlMessageValue('de', 'common.table.head.employe', Store)}/>
                    },
                    {
                        id: 'start_time',
                        type: "date",
                        translation: 'common.date',
                        param: 'start_time',
                        method: 'end_like'
                    },
                    {
                        id: 'status',
                        translation: 'common.status',
                        param: 'status',
                        method: '=',
                        individual: <Form.Group controlId={'status'} key={"select"} className="col" >
                            <Form.Label>Status</Form.Label>
                            <Form.Select  aria-label="Floating label select example" defaultValue={localStorage.getItem('TimeTracking_searchValue_status') as string}>
                                <option value={''}>Alle Zeiterfassungen</option>
                                <option value={'null'}>Inaktive Zeiterfassungen</option>
                                <option value={'1'}>Aktive Zeiterfassungen</option>
                            </Form.Select>
                        </Form.Group>
                    },
                ],
                button: ['common.search', 'common.del.search', 'common.button.new_timetracking']
            }],
            'table': {
                'tableBody': {
                    'data': response,
                    'dataID': 'id',
                    'underdata': true,
                    'keys': ['id','status', 'start_time', 'name', 'start_time', 'break_start', 'break_end', 'end_time', 'time', 'distance', 'description'],
                    'type': ['string','status', 'dateCutfromdate', 'string', 'timeCut', 'time', 'time', 'timeCut', 'timeline|start_time|end_time', 'km', 'string'],
                },
                'tableHeader': [<IntlMessage Store={Store} messageId={'common.table.head.id'}/>,<IntlMessage Store={Store} messageId={'common.status'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.date'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.employe'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.time_start'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.breack_from'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.breack_until'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.time_end'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.duration'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.driven_km'}/>,
                    <IntlMessage Store={Store} messageId={'common.table.head.remark'}/>],
                'tableHeaderWidth': ['5%','5%', '10%', '10%', '10%', '10%', '10%', '10%', '5%', '10%', '15%'],
                'tablecolor': ['light'],
                'menu': {
                    'data': response,
                    'menuDisable': false,
                    'menutriggerDelete': true,
                    'menuItem': [<IntlMessage Store={Store} messageId="common.edit"/>,
                        <IntlMessage Store={Store} messageId="common.delete"/>],
                    'menuroute': ['/timetracking/Detail', '/timetracking/delete/1'],
                    'menuParams': ['', 'id', 'id'],
                    'menuIcons': ['pencil', 'Delete'],
                },
            }
        };
        if (response !== undefined && response.length > 0 && ALLCustomer !== undefined) {
            return (
                <>
                    <div className={'site'}>
                        <SearchJSX setSearchValue={setSearchValue} Store={Store}
                                   SearchConfig={Configuration['SearchConfig']}/>
                        <br style={{clear: 'both'}}/>
                        <PielersTable Configuration={Configuration['table']}/>
                        <PaginationJSX response={response} history={history} to={'overview'}
                                       handleSuccess={handleSucces} destination={destination}/>
                    </div>

                </>
            );
        } else {
            return (
                <><SpinnerJSX messageId={'common.loading'} Store={Store}/></>
            );
        }

}

export default TimeTrackingOverview;
